@font-face {
    font-family: 'OpenSans';
    src: url('../../font/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../../font/OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url('../../font/OpenSans-SemiBold.ttf');
}