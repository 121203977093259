

.App {
    text-align: center;
    // background-image: url("bbc_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
   


  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .floatButton{
    display: flex;
    border-radius:0.688rem 0.688rem 0rem 0rem;
    position: fixed;
    flex-direction: row;
    height: 2rem;
    padding-left: 1.331rem;
    padding-right: 1.331rem;
    padding-bottom: 0.719rem;
    padding-top: 0.775rem;
    background-color: black;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  
    justify-content: center;
    align-items: center;
    z-index: 99999;
    &::after{
      border-radius: 0.688rem;
      padding: 1rem 0.1rem;
      height: inherit;
      content: "";
      position: fixed;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: -1;
      width: 100%;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 0.625rem solid transparent;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 768px) {
      height: 2.813rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 0.625rem;
      padding-top: 0.625rem;
    }
  }
  ._text{
    font-family: 'OpenSansBold';
    font-size: 1.125rem;
    font-weight: 800 !important;
    line-height: 1.5rem;
    margin-left: 0.625rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      font-weight: 800 !important;
      line-height: 1.4rem;
    }
  }
  .msgImg{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    @media screen and (max-width: 768px) {
        width: 1.313rem;
        height: 1.313rem;
      }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  