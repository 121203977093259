/* src/App.css */

@import './variables.scss';


.chat-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 1rem;
  right: 0;
  width: 100%;
  display: flex;
  height: 100vh;
  border: 1px solid #ccc;
  padding: 10px 10px 0px 0px !important;
  background-color: rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0 !important;
    }
  .sub-container{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    max-height: 90vh;
    background-color: #000;
    flex-direction: column;
    border-radius: 1rem 1rem 0rem 0rem !important;
    display: flex;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .header{
    height: 3.75rem;
    width: 100%;
    justify-content: center;
    align-content: 'flex-start';
    background-color: #000000;
    color: #ffffff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .title{
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0rem 1rem 0rem 1rem;
    }
  }
._textTitle{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0rem 1rem 0rem 1rem;
}
  .chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
  .messages {
    background-color: #fff;
    
    padding: 20px;
    background-color: '#fffff';
    overflow-y: auto; 
    display: flex;
    flex-grow: 1;
    flex-direction: column; 
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    
  }
  
  .message {
    margin: 0.5rem 0rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
    border-radius: 4px;
    display: grid;

  }
  .input{
    display: flex;
    flex-direction: row;
    padding: 10px;
    // border : 0px 1px 0px 0px solid #000 !important;
    border-top: 0px !important;
    border-left: 0px !important; 
    border-right: 0px !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
    outline: none !important;
    background-color: #f5f5f5;
  }
  .image {
    position:absolute;
    top: 53%;
    right:2rem;
    width:20px;
    height:20px;
    transform: translateY(-60%);
}
  .user {
    max-width: 80%;
    width: auto;
    align-self: flex-end;
    justify-items: flex-end;
    background-color: $red;
    // border: 1px solid #1d1d1d;
    ._text{
        color: white;

        //long text should break and should not get out of the box, to solve this uncomment below line
        word-break: break-word;
        // word-break: break-all;

        text-align: right;
        p{
            margin: 0;
            strong {
              font-family: monospace;
              font-weight:bold;
          }
        }
    }
  }
  ._text{
    font-family: 'OpenSans' !important;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.362rem;
    margin: 0 !important;
    padding: 1rem;
    //text should not get out of the box
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    
    
  }
  
  .bot {
    max-width: 80%;
    // width: auto;
    align-self: flex-start;
    justify-items: flex-start;
    background-color: $purple;
    // border: solid 1px $purple;
    ._text{
        color: white;
        text-align: left;
        word-break: break-word;
        // word-break: break-all;



        p {
            margin: 0;

            strong {
              font-family: monospace;
              font-weight:bold;
          }
        }

    }
  }
  
  .input-container {
    background-color: #fff;
    position: relative;
    display: flex;
    padding: 10px 20px;
  }
  
  .input-container input {
    flex: 1;
    height: 2.5rem;
    padding: 10px;
  }
  
  .input-container button {
    padding: 10px 20px;
    background-color:#f5f5f5;
border-radius: 0;
border-bottom: 1px solid #000 !important;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .hero-card {
    padding: 10px;
    
  }
  
  .hero-card-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .hero-card-buttons {
    display: flex;
    flex-direction: column;
    // position:absolute;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .hero-card-buttonsmorethan2len{
    display: flex;
    flex-direction: row;
    // position:absolute;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    // width: 500px;

  }
  
  .hero-card-button {
    background-color: #000000;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin: 5px 5px;
    border-radius: 4px;
    text-decoration: none;
  }

  .hero-card-rating {
    background-color: #000000;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin: 5px 0px;
    text-decoration: none;
  }

  .selected {
    background-color: #fff;
    color: #000000;
  }
  
  .hero-card-button:hover {
    background-color: #000000;
  }

  .leftAlign{
    background-color: #fff;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-end;
    padding-right: 1rem !important;
  }
  footer{
    background-color: #fff !important;
  }
  ._downArrow{
    background-color: #fff !important;
    justify-content: center;
  }
  .formitem{
    //label and input bos should be in same line and keep the labels on left side
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  .formlabel{
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.362rem;
    
  }
  .formlabelwithbottomborder{
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    
  }

  .forminput{
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    background-color: #F5F5F5;
    //remove border
    border: 0px;
    height: 43px;
  }
  .forminputwithbottomborder{
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    background-color: #F5F5F5;
    height: 43px;
    //bottom border with 1px width and black color
    border: 0px;

    border-bottom: 2px solid #000000;

  }
  .formsubmit{
    //blue button with full width and white text with little borler radius
    background-color: rgb(0, 0, 0);
    color: #fff;
    padding: 14px;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    border: 0px;
    width: 100%;
    cursor: pointer;

  }
  .formrating{
    //rating stars should be in same line
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    font-size: 1rem;

  }
  .endChatButton{
    cursor: pointer;
    margin-top: 24px;
    //white button with full width and black text with little borler radius
    background-color: #fff;
    color: #000;
    padding: 14px;
    font-size: 16px;
    font-weight: 500;

    border-radius: 8px;
    border: 1px solid #000;
    width: 100px;
    // height: 36px;

    p{
      margin: 0;
      padding: auto;
    }
  }
  .formStarOut{
    min-width: 80px;
  }

  .formStar{
    //rating stars should be in same line
    background-color: white;
display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    
    border-width: 1px 0px 1px 1px;
    border-color: $black;
    border-style: solid;
    
    //black border of 3 px;
    font-size: 3rem;
  }
  .formStarfilled{
display: flex;
cursor: pointer;

    //rating stars should be in same line
    background-color: $black;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 10px;
    
    
    
    //black border of 3 px;
    border: 1px solid $black;
    font-size: 3rem;
  }

  .formStarfirst{
display: flex;
cursor: pointer;

    //rating stars should be in same line
     //rating stars should be in same line
     background-color: white;

     align-items: center;
     justify-content: center;
     padding: 10px;
     
     
     
     //black border of 3 px with left top left bottom border radius
      border-top-left-radius: 38px;
      border-bottom-left-radius: 38px;
  border-width: 1px 0px 1px 1px;
    border-color:$black;
    border-style: solid;
     font-size: 3rem;
  }
  .formStarfirstfilled{
display: flex;
cursor: pointer;

//rating stars should be in same line
     //rating stars should be in same line
     background-color: $black;
      color: white;

     align-items: center;
     justify-content: center;
     padding: 10px;
     
     
     
     //black border of 3 px with left top left bottom border radius
      border-top-left-radius: 38px;
      border-bottom-left-radius: 38px;
     border: 1px solid $black;

     font-size: 3rem;
  }
  .formStarlast{
display: flex;
cursor: pointer;

    //rating stars should be in same line
    background-color: white;

    align-items: center;
    justify-content: center;
    padding: 10px;
    
    
    
    //black border of 3 px with right top right bottom border radius
    border-top-right-radius: 38px;
    border-bottom-right-radius: 38px;
    // borders except left side
    border: 1px solid $black;
    font-size: 3rem;
  }
  .formStarlastfilled{
display: flex;
cursor: pointer;

    //rating stars should be in same line
    background-color: $black;
    color: white;

    align-items: center;
    justify-content: center;
    padding: 10px;
    
    
    
    //black border of 3 px with right top right bottom border radius
    border-top-right-radius: 38px;
    border-bottom-right-radius: 38px;
    border: 1px solid $black;
    font-size: 3rem;
  }
  .ratenumber{
    //rating number should be in same line
    cursor: 'pointer';
    padding: 8px;
    font-size: 16px;
    line-height: 1.362rem;
  }
  .bottext{
    color: #6E1B6B;
    font-size: 16px;
    font-weight: 600;
  }
  .line{
    //horizontal line with 1px width and black color
    border: 1px solid #0000001f;
    width: 100%;
    margin-top: 16px;
  }
  .TextBlock{
    font-size: 16px;font-weight: 700;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    align-self: flex-start;
    display: flex;
    margin-bottom: 18px;
  }
}
.footer-button{
  //blue button with full width and white text with little borler radius
  padding: 4px;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  background: transparent;

  text-decoration: none;
  border: 0px;
  width: 100%;
}

.send-button {
  background-color: rgba(0, 0, 0, 0.3);
}


.formStarHorizontal{
  //rating stars should be in same line
  background-color: white;
display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  
  border-width: 1px 1px 0px 1px;
  border-color: #000000;
  border-style: solid;
  
  //black border of 3 px;
  font-size: 3rem;
}
.formStarfilledHorizontal{
display: flex;
cursor: pointer;

  //rating stars should be in same line
  background-color: $black;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 10px;
  
  
  
  //black border of 3 px;
  border: 1px solid #000;
  font-size: 3rem;
}

.formStarfirstHorizontal{
display: flex;
cursor: pointer;

  //rating stars should be in same line
   //rating stars should be in same line
   background-color: white;

   align-items: center;
   justify-content: center;
   padding: 10px;
   
   
   
   //black border of 3 px with left top left bottom border radius
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
border-width: 1px 1px 0px 1px;
  border-color: $black;
  border-style: solid;
   font-size: 3rem;
}
.formStarfirstfilledHorizontal{
display: flex;
cursor: pointer;

//rating stars should be in same line
   //rating stars should be in same line
   background-color: $black;
    color: white;

   align-items: center;
   justify-content: center;
   padding: 10px;
   
   
   
   //black border of 3 px with left top left bottom border radius
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // border-bottom-left-radius: 8px;
   border: 1px solid $black;

   font-size: 3rem;
}
.formStarlastHorizontal{
display: flex;
cursor: pointer;

  //rating stars should be in same line
  background-color: white;

  align-items: center;
  justify-content: center;
  padding: 10px;  
  
  //black border of 3 px with right top right bottom border radius
  // border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  
  // borders except left side
  border: 1px solid $black;
  font-size: 3rem;

}
.formStarlastfilledHorizontal{
display: flex;
cursor: pointer;

  //rating stars should be in same line
  background-color: $black;
  color: white;

  align-items: center;
  justify-content: center;
  padding: 10px;
  
  
  
  //black border of 3 px with right top right bottom border radius
  // border-top-right-radius: 8px;
  
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid $black;
  font-size: 3rem;
}